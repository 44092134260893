import './index.scss';

import Project from '../../../components/Project';
import { Image } from 'antd';
import { ProjectLink } from '../../../components/links';

function Logger() {
    return (
        <Project title="GamuLogger" repo="https://github.com/T0ine34/ortelo">
            <p>GamuLogger is a Python library that allows you to create logs in a simple way.</p>
            <p>The idea came when I was developing <ProjectLink project='ortelo'/>. I created most of build scripts in Python and I wanted to have a simple way to log what was happening.
            I did not find any library that was really fitting my needs so I decided to create my own library, intended to be simple to use, while being quite powerful.
            I initially also created a typescript version of this library, but as I was developing it, I realized that I don't really have any use for it, so I decided to drop it and focus on the Python version.
            </p>
        </Project>
    );
}

export default Logger;