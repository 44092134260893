import "./index.scss";

import { PageContext } from '../../../context';
import { useContext } from 'react';

function ProjectLink({ project } : { project: string }) {
    const { setCurrent } = useContext(PageContext);
    return (
        <div className="project-link">
            <a onClick={() => setCurrent(project)}>{project}</a>
        </div>
    );
}


export default ProjectLink;