import './index.scss';

import { Url } from '../links';

function Project({ title, repo, children }: { title: string, repo?: string, children: React.ReactNode }) {
    return (
        <section className="Project">
            <h3>{title}</h3>
            {repo && <Url url={repo} minimal>Repository</Url>}
            {children}
        </section>
    );
}

export default Project;