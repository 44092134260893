import './index.scss';

import { PageContext } from '../../context';
import React, { useContext } from 'react';

import Logger from '../../content/projects/Logger';

function Projects({ children }: { children: React.ReactNode }) {
        const { current, setCurrent } = useContext(PageContext);
    
        if (current !== 'projects') {
            return null;
        }
    
        return (
            <Logger />
        );
}

export default Projects;